module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":10,"elements":["h2","h3"],"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":630,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lucas wiki","short_name":"LucasWiki","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"content/assets/icon.png","icons":[{"src":"content/assets/favicons/favicon-16.png","sizes":"16x16","type":"image/png"},{"src":"content/assets/favicons/favicon-32.png","sizes":"32x32","type":"image/png"},{"src":"content/assets/favicons/favicon-48.png","sizes":"48x48","type":"image/png"},{"src":"content/assets/favicons/favicon-64.png","sizes":"64x64","type":"image/png"},{"src":"content/assets/favicons/favicon-128.png","sizes":"128x128","type":"image/png"},{"src":"content/assets/favicons/favicon-256.png","sizes":"256x256","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"affc42cd17a750edefdb36c59ddc3aec"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-129803354-1","G-DSE7CZZQVM"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../../../var/task/node_modules/@vercel/gatsby-plugin-vercel-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
